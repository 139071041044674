import React, { useRef } from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function Style() {

  const { colours } = useStaticQuery(
    graphql`
      query {
        colours: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(colours)/"}}) {
          nodes {
            frontmatter {
              primary
              secondary
            }
          }
        }
      }
    `
  )
  
  const randIndex = Math.round(Math.random()*(colours.nodes.length-1))
  const colour = useRef(colours.nodes[randIndex].frontmatter || colours.nodes[0].frontmatter)

  return (
    <Helmet>
      <style type="text/css">{`
          
        html, body {
          background: ${colour.current.secondary};
          color: ${colour.current.primary};
        }

        a {
          color: ${colour.current.primary};
        }

        .project {
          border-top-color: ${colour.current.primary};
        }
        .project-index {
          background: ${colour.current.secondary};
        }
        
      `}</style>
    </Helmet>
  )
}

export default Style
