import React, { useState } from "react"
import ImageOnLoad from 'react-image-onload'

const Media = React.forwardRef((props, ref) => {

  const { media, alt, open } = props
  const [ loaded, setLoaded ] = useState(false)

  let currentMedia
  let width = media.width ? media.width*0.5 : 'auto'
  let height = media.height ? media.height*0.5 : 'auto'

  if ( media.media_type === "video" ) {
    currentMedia = <video width={width} controls src={media.media} autoPlay loop></video>
  } else {
    currentMedia = <ImageOnLoad className="project-image" src={media.media} alt={alt} onLoad={(e) => { setLoaded(true) }} width={width} height={height} />
  }

  return (
    <div 
      ref={ref}
      className={`project-media ${media.media_type === "video" ? 'is-video' : ''} ${loaded ? 'is-loaded' : ''}`} 
      style={{ height: width, width: height }}
      onClick={() => props.onClick(media)}>
      {open && currentMedia}
    </div>
  )
})

export default Media
