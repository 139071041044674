import React from "react"
import Media from "./Media"

const Project = React.forwardRef((props, ref) => {

  const { index, node, maxLen, open, toggle } = props
  const DOMProjectsImages = []

  const pad = function(num, size) {
    var s = String(num);
    while (s.length < (size || 2)) {s = "0" + s;}
    return s;
  }

  const onImageClick = (indexMedia, media) => {

    let img = DOMProjectsImages[indexMedia]

    if (media.mediaType !== 'video') {
      props.scrollTo( img.offsetTop - (document.body.clientWidth*0.5) + ( img.clientHeight * 0.5 ) )
    }
  }

  return (<div className="project" ref={ref}>
                
    <div className="project-info" onClick={ () => { toggle( index ) } }>

      <div className="project-intro">

        <div className="project-basic">
          <div className="project-index">{pad(index+1)}/{pad(maxLen)}</div>
          <div className="project-basic-details">
            <div className="project-year">{node.year ? node.year : ''}</div>
            <div className="project-client">{node.client ? node.client : ''}</div>
          </div>
        </div>

        <div className="project-copyright">{node.copywrite ? node.copywrite : ''}</div>

        <div className="project-links">
          {node.link_one_label && node.link_one_url ? <a href={node.link_one_url} target="_blank" rel="noopener noreferrer">{node.link_one_label}</a> : ''}
          {node.link_two_label && node.link_two_url ? <a href={node.link_two_url} target="_blank" rel="noopener noreferrer">{node.link_two_label}</a> : ''}
        </div>
      </div>
      
      <div className="project-title">{node.title}</div>
    </div>
    <div className="project-details">
      <div className="project-description">
        {node.description}
      </div>
      <div className="project-gallery">
        {node.gallery ? node.gallery.map( (media, indexMedia) => {
          return <Media open={open} media={media} key={indexMedia} alt={node.title} onClick={() => onImageClick(indexMedia, media)} ref={ i => DOMProjectsImages[indexMedia] = i }  />
        } ) : ''}
      </div>
    </div>
  </div>)
})

export default Project
