import React, { useState } from "react"
import { graphql } from 'gatsby'
import { gsap, TweenLite, Cubic, CSSPlugin, ScrollToPlugin } from "gsap/all"
import { Helmet } from "react-helmet"

import Style from "../components/style"
import SEO from "../components/seo"
import Header from "../components/Header"
import Project from "../components/Project"

import "../sass/style.scss"

const IndexPage = ({ data }) => {

  gsap.registerPlugin(CSSPlugin, ScrollToPlugin)

  const { projects } = data
  const [ allProjects, setAllProjects ] = useState(projects.nodes)
  
  // refs
  const scrollContainerRef = React.createRef()
  const DOMProjects = []

  const toggleProject = (index) => {
    
    let updatedProjects = [...allProjects]

    let projectDetails = DOMProjects[index].querySelector('.project-details')
    let maxHeight = projectDetails.querySelector('.project-description').clientHeight + projectDetails.querySelector('.project-gallery').clientHeight

    let video = DOMProjects[index].querySelector('video')
    if ( video ) {
      updatedProjects[index].isOpen ? video.pause() : video.play()
    }

    TweenLite.killTweensOf(projectDetails)
    TweenLite.to( projectDetails, 0.5, { maxHeight: updatedProjects[index].isOpen ? '0' : maxHeight, ease: Cubic.easeInOut })

    updatedProjects[index].isOpen = !updatedProjects[index].isOpen
    setAllProjects(updatedProjects)
  }

  const scrollTo = (scrollTarget) => {
    TweenLite.killTweensOf(scrollContainerRef.current)
    TweenLite.to( scrollContainerRef.current, 0.5, { scrollTo: scrollTarget, ease: Cubic.easeInOut })
  }

  const randArrowIndex = Math.ceil(Math.random()*4)

  return (
    <main className="scroll-wrapper theme-colour">
      <Style />
      <SEO />
      <Helmet>
        <style type="text/css">{`
          .project-image {
            cursor: url('/images/arrows/arrow_${randArrowIndex}.png') 64 50, pointer;
          }
        `}</style>
      </Helmet>
      <div className="scroll-container" ref={scrollContainerRef}>

        <Header scrollTo={scrollTo} />

        <div className="projects">
          {allProjects ? allProjects.map((project, index) => {

            const node = project.frontmatter
            return <Project key={index} index={index} node={node} maxLen={allProjects.length} toggle={toggleProject} open={project.isOpen} ref={ p => DOMProjects[index] = p } scrollTo={scrollTo} />

          }) : ''}
        </div>
      </div>
    </main>
  )
}

export default IndexPage

export const query = graphql`
  query HomeQuery {
    projects: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(projects)/"}}, sort: {order: DESC, fields: frontmatter___year}) {
      nodes {
        frontmatter {
          client
          copywrite
          description
          link_one_label
          link_one_url
          link_two_label
          link_two_url
          title
          year
          gallery {
            height
            media
            media_type
            width
          }
        }
      }
    }
  }
`