import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { Remarkable } from 'remarkable'
import { gsap, TweenLite, Cubic, CSSPlugin, ScrollToPlugin } from "gsap/all"

const Header = (props) => {

  const { texts } = useStaticQuery(
    graphql`
      query {
        texts: markdownRemark(fileAbsolutePath: {regex: "/(texts)/"}) {
          frontmatter {
            email
            instagram
            intro
            title
          }
        }
      }
    `
  )

  gsap.registerPlugin(CSSPlugin, ScrollToPlugin)

  const md = new Remarkable({html:true})
  const intro = md.render(texts.frontmatter.intro)

  const introRef = React.createRef()
  const [ introVisible, setIntroVisible ] = useState(false)

  const toggleIntro = () => {

    props.scrollTo( introVisible ? 0 : introRef.current.offsetTop )
    TweenLite.killTweensOf(introRef.current)
    TweenLite.to( introRef.current, 0.5, { maxHeight: introVisible ? '0' : '120rem', ease: Cubic.easeInOut })

    setIntroVisible(!introVisible)
  }
  
  return (<div><header onClick={toggleIntro}>
    <h1>{texts.frontmatter.title}</h1>
    <a href="/" className="logo" onClick={(e) => { e.stopPropagation() }}>
      <img src="/images/icons/logo.svg" alt={texts.frontmatter.title} />
    </a>
    <div className="links">
      <a href={texts.frontmatter.instagram} target="_blank" rel="noopener noreferrer">
        <img src="/images/icons/insta.svg" alt="instagram" />
      </a>
      <a href={`mailto:${texts.frontmatter.email}`} target="_blank" rel="noopener noreferrer">
        <img src="/images/icons/mail.svg" alt="mail" />
      </a>
    </div>
  </header>

  <div className="intro-wrapper" ref={introRef}>
    <div className="intro">
      <div className="intro-content" dangerouslySetInnerHTML={{ __html : intro }}></div>
    </div>
  </div></div>)
}

export default Header
